import { parse } from "best-effort-json-parser";

export function parseJsonAndTextEnhanced(input) {
    const results = [];
    let balance = 0; // Track the balance of opening and closing braces
    let potentialStart = -1; // Potential start of a JSON object
    let part = ''; // Accumulate potential JSON or trailing text
  
    // Function to try parsing and reset part
    const tryParseJsonAndReset = (jsonStr) => {
        try {
            const obj = JSON.parse(jsonStr);
            results.push(obj); // If successful, push to results
            part = ''; // Reset part
        } catch {
            // If parsing fails, it wasn't valid JSON; do nothing here
        }
    };
  
    for (let i = 0; i < input.length; i++) {
        const char = input[i];
        part += char; // Accumulate characters
  
        if (char === '{') {
            if (balance === 0) potentialStart = i;
            balance++;
        } else if (char === '}') {
            balance--;
            if (balance === 0 && potentialStart !== -1) {
                // Try parsing when braces are balanced
                tryParseJsonAndReset(part);
                potentialStart = -1; // Reset start index
            }
        }
    }
  
    // If there's any non-empty string left in part after the loop, it's trailing text
    if (part.trim()) {
        // If no JSON was successfully parsed and part has content, try parsing one last time
        // This is useful if the input was a single JSON object with trailing spaces
        if (results.length === 0) {
            tryParseJsonAndReset(part);
        }
        // If still not parsed, it's definitely trailing text or the whole input is not JSON
        if (part.trim()) {
            results.push(part.trim());
        }
    }
  
    return results.map(s=>{
      try {
        if(typeof s === "string" && !s.startsWith("{")) return s
        return parse(typeof s === "object" ? JSON.stringify(s) : s)
      } catch (error) {
        // console.error("🚀 ~ error:", error)
        return s
      }
    });
  }


export function protectLatexInMarkdown(input) {
    // Regular expression to match LaTeX expressions enclosed by \[ and \]
    // It uses negative lookbehind and lookahead to avoid matching escaped brackets
    const latexPattern = /(?<!\\)\[(.*?)(?<!\\)\](?!\\)/gs;
  
    // This function will be called for each match found by the regex
    // It replaces each backslash in the match with two backslashes
    const replaceBackslashes = (match) => {
      // Replace single backslash with double backslashes inside the matched text
      return match.replace(/\\/g, '\\\\');
    };
  
    // Replace each LaTeX expression in the input with its protected form
    return input.replace(latexPattern, replaceBackslashes);
  }
  
export const preprocessLaTeX = (content: string) => {
    // Replace block-level LaTeX delimiters \[ \] with $$ $$
    if(!content) content = ''
    content = protectLatexInMarkdown(content)
    const blockProcessedContent = content.replace(
      /\\\[(.*?)\\\]/gs,
      (_, equation) => `$$${equation}$$`,
    );
    // Replace inline LaTeX delimiters \( \) with $ $
    const inlineProcessedContent = blockProcessedContent.replace(
      /\\\((.*?)\\\)/gs,
      (_, equation) => `$${equation}$`,
    );
    return (inlineProcessedContent);
};

export const utils = {
    parseJsonAndTextEnhanced,
    protectLatexInMarkdown,
    preprocessLaTeX
}

export default utils