import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import 'katex/dist/katex.min.css';
import { MathJax } from "better-react-mathjax";
import remarkGfm from 'remark-gfm'
import { parseJsonAndTextEnhanced, preprocessLaTeX } from "./utils";
import { fetchEventSource } from "@microsoft/fetch-event-source";

// type EndResponse = string;
// type ResponseTypes = QueryBlock | TutorBlock | EndResponse

const ResponseRenderer = (response) => {
  const rj = response.json
  console.log("🚀 ~ ResponseRenderer ~ response:", response, !rj || !Array.isArray(rj))
  if (!rj || !Array.isArray(rj)) return (<div>{response.content || ""}</div>)
  return (
    <div>
      {rj.map((block, idx) => {
        let blockType;
        /**
         * Determine block type
         */
        if (typeof block == "string") blockType = "end_response"
        else if (typeof block === "object" && block.query) blockType = "query"
        else if (typeof block === "object" && ["chapter_id", "subchapter_id", "book_id"].find(k => block.hasOwnProperty(k))) blockType = "reference"
        else if (typeof block === "object" && ["original_quiz_question", "score_expected_answer", "score_explanation", "score_number", "follow_up_quiz_question"].find(k => block.hasOwnProperty(k))) blockType = "tutor"
        else blockType = "unknown"
        const blockContent = block.content || block
        console.log("🚀 ~ {rj.map ~ blockType:", block, rj, blockType, blockContent)
        return (
          <div key={"block-" + idx}>
            <div>{blockType}</div>
            <MathJax dynamic hideUntilTypeset="every">
              <ReactMarkdown remarkPlugins={[remarkGfm]} remarkRehypeOptions={{ passThrough: ['link'] }}>
                {blockType === "end_response" ? preprocessLaTeX(blockContent) : JSON.stringify(Object.entries(blockContent).map(([k, v]) => `${k}: ${v}`), null, 2)}
              </ReactMarkdown>
            </MathJax>
          </div>
        );
      })}
    </div>
  );
}

const App = ({ version }) => {

  const baseUrl = process.env.REACT_APP_PROXY_API_URL
  const [messages, setMessages] = useState([]);
  const [job, setJob] = useState({ content: "" });
  const [name, setName] = useState("Ethan");
  const [promptOverride, setPromptOverride] = useState("");
  const [bookId, setBookId] = useState("biochem");
  // eslint-disable-next-line
  const [userId, setUserId] = useState("abcd1234");
  const [agentMode, setAgentMode] = useState(true);
  // eslint-disable-next-line
  const [chapterId, setChapterId] = useState("c394ede2-f15c-470c-b4f2-e45e498c98a0")


  const [books, setBooks] = useState([
    {
      id: "biochem",
      title: "...loading, please wait",
      chapters: [
        {
          id: "c394ede2-f15c-470c-b4f2-e45e498c98a0",
          title: "...loading, please wait",
        }
      ]
    },
  ]);

  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    async function fetchBooks() {
      let response = await fetch(`${baseUrl}/books`, {
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
      });
      response = await response.json();
      setBooks(response);
      setBookId(response[0].id);
      setChapterId(response[0].chapters[0].id)
    }
    fetchBooks()
  }, [baseUrl])

  const onSendMessage = async () => {
    const input = document.getElementById("message-input");
    if (!input) return;
    const message = input.value;
    input.value = "";
    //@ts-ignore
    setMessages((prev) => [...prev, { role: "user", content: message }]);
    setProcessing(true);
    // append the user message to the list of messages
    // call api and list for stream (sse)
    let currentMessage = {}
    const response = await fetchEventSource(`${baseUrl}/copilot`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
      body: JSON.stringify({
        user_id: userId || "abcd1234",
        resource_id: bookId || "biochem",
        "use_agent": agentMode,
        resource_type: "book",
        first_name: name || "Ethan",
        chat_message: message || "Where are VLD's made?",
        chapter_id: chapterId,
        chat_history: [
          ...messages,
          { role: "user", content: message },
          { role: "assistant", content: "" },
        ],
        prompt_override: promptOverride
      }),
      onmessage: (e) => {
        const d = JSON.parse(e.data)
        console.log("🚀 ~ onmessage ~ e", e.event, d)
        if(d.output) d.content = d.output
        currentMessage = d
        setJob((prev) => ({
            ...prev,
            ...d
          }));
      },
      onerror: (e) => {
        console.error("🚀 ~ onerror ~ e", e)
      }
    });
    console.log("DONE SSE", currentMessage, response);
    setMessages((prev) => [
      ...prev,
      { role: "assistant", content: currentMessage.json.find(data => typeof data == "string") },
    ]);
    setJob({ content: "" });
    setProcessing(false);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          position: "relative",
        }}
      >
        <label>First Name</label>
        <input onChange={(e) => setName(e.target.value)} value={name} />
        <br />
        <label>Agent Mode</label>
        <input type="checkbox" onChange={(e) => {
          console.log(e.target.checked)
          setAgentMode(e.target.checked)
        }} checked={agentMode} />
        <br />
        <label>Operating Modes (dev)</label>
        <select onChange={(e) => setPromptOverride(e.target.value)}><option value="">Green</option><option value="rallyintel/ek-concise">Red</option></select>
        <br />
        {/* <label>User Id</label>
        <input onChange={e => setUserId(e.target.value)} value={userId} /> */}
        <label>Book Selection</label>
        <select onChange={(e) => setBookId(e.target.value)} value={bookId}>
          {books.map((book) => (
            <option value={book.id} key={book.id}>
              {book.title}
            </option>
          ))}
        </select>
        <label>Chapter Selection</label>
        <select onChange={(e) => setChapterId(e.target.value)} value={chapterId}>
          {books.find(book => book.id === bookId)?.chapters.map((chapter) => (
            <option value={chapter.id} key={chapter.id}>
              {chapter.order}. {chapter.title}
            </option>
          ))}
        </select>
      </div>
      <br />
      <hr width="100%" />
      <br />
      <div
        style={{
          height: "80vh",
          display: "grid",
          gridTemplateRows: "auto 60px 15px",
        }}
      >
        <div style={{ paddingBottom: "6rem" }}>
          {messages.map((message, idx) => {
            return (
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  padding: "15px 0px",
                  whiteSpace: "pre-wrap",
                }}
                key={"message-" + idx}
              >
                <div>
                  <div className={`avatar-${message.role}`}>
                    {message.role[0].toUpperCase()}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "800",
                      fontSize: "12px",
                      textTransform: "uppercase",
                      color: "#CACACA",
                    }}
                  >
                    {message.role}
                  </div>
                  <MathJax dynamic hideUntilTypeset="every">
                    <ReactMarkdown remarkPlugins={[remarkGfm]} remarkRehypeOptions={{ passThrough: ['link'] }}>
                      {preprocessLaTeX(Object.values(parseJsonAndTextEnhanced(message?.content)).find(x => typeof x == "string"))}
                    </ReactMarkdown>
                  </MathJax>
                </div>
              </div>
            );
          })}
          {processing && (
            <div
              style={{
                display: "flex",
                gap: "10px",
                padding: "10px 0px",
                whiteSpace: "pre-wrap",
              }}
            >
              <div>
                <div className="avatar-assistant">A</div>
              </div>
              <div
                style={{ display: "flex", flexDirection: "column", gap: "8px" }}
              >
                <div
                  style={{
                    fontWeight: "800",
                    fontSize: "12px",
                    textTransform: "uppercase",
                    color: "#CACACA",
                  }}
                >
                  {job?.role}
                </div>
                <ResponseRenderer {...job} />
              </div>
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "10px 0px",
            position: "fixed",
            bottom: "1rem",
            width: "600px",
          }}
        >
          <div
            style={{
              display: "flex",
              padding: "10px 0px",
            }}
          >
            <input
              id="message-input"
              placeholder="enter message..."
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  onSendMessage();
                }
              }}
              disabled={processing}
            />
            <button onClick={onSendMessage} disabled={processing} style={{ color: "white" }}>
              &#8627;
            </button>
          </div>
          <label>&nbsp;EK Chat - v{version}</label>
        </div>
      </div>
    </>
  );
};

export default App;