import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import pack from "../package.json"
import { MathJaxContext } from "better-react-mathjax";

const config = 
// v3
{
  tex: {
    tags: 'ams',
    inlineMath: [ ["$", "$"], ["\\(", "\\)"] ],
    displayMath: [ ["$$", "$$"], ["\\[", "\\]"] ],
  },
  options: {
    ignoreHtmlClass: 'tex2jax_ignore',
    processHtmlClass: 'tex2jax_process',
  },
} || 
// v2
{
  "fast-preview": {
    disabled: true,
  },
  tex2jax: {
    inlineMath: [
      ["$", "$"],
      ["\\(", "\\)"],
    ],
    displayMath: [
      ["$$", "$$"],
      ["\\[", "\\]"],
    ],
  },
  messageStyle: "none",
};

const REACT_APP_PROXY_API_URL = process.env.REACT_APP_PROXY_API_URL;
console.log("🚀 ~ REACT_APP_PROXY_API_URL:", process.env, REACT_APP_PROXY_API_URL)

function MissingEnvVarAlert() {
  return (
    <div className="app-wrapper">
      <h1>Environment Variable Missing</h1>
      <p>Hey there! It looks like the REACT_APP_PROXY_API_URL environment variable isn't set. Please check your .env file.</p>
    </div>
  );
}

// Get the root element
const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

// Conditional rendering based on the environment variable
const content = !!REACT_APP_PROXY_API_URL ? (
  <div className="app-wrapper">
    <MathJaxContext config={config} version={3}>
    <App version={pack.version}/>
    </MathJaxContext>
    </div>
) : (
  <MissingEnvVarAlert />
);

// Render the app or the alert based on the condition
root.render(content);
